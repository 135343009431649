import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";

import "../styles/index.scss";

const IndexPage = ({ data }) => (

  <>
    <Helmet>
      <title>{data.site.siteMetadata.title}</title>
      <meta name="description" content={data.site.siteMetadata.description} />
    </Helmet>
    <main>
      umm, check out <a href="https://ghosh.pro/links">github</a> for now, maybe 👀
    </main >
  </>
);

export default IndexPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;

